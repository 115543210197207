import {
  Customer,
  CustomersByPage,
  RetrievedCustomer,
} from 'models/customer';
import useSWR, { KeyedMutator } from 'swr';
import { fetcher } from './useFetcher';

type GetCustomersByPage = {
  customersByPage: CustomersByPage | undefined;
  error: any;
  isLoadingCustomers: boolean;
  mutate: KeyedMutator<CustomersByPage>;
};

type GetCustomer = {
  customer: RetrievedCustomer | undefined;
  error: any;
  isLoadingCustomer: boolean;
  mutate: KeyedMutator<RetrievedCustomer | undefined>;
};

const useGetCustomersByPage = (
  page: number = 1
): GetCustomersByPage => {
  const { data, error, isLoading, mutate } = useSWR<CustomersByPage>(
    `/customer?page=${page}`,
    fetcher,
    {
      keepPreviousData: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    customersByPage: data,
    error,
    isLoadingCustomers: isLoading,
    mutate,
  };
};

const useGetCustomer = (specialId: string): GetCustomer => {
  const { data, error, isLoading, mutate } = useSWR<
    RetrievedCustomer | undefined
  >(`/customer/find/${specialId}`, fetcher);

  return {
    customer: data,
    error,
    isLoadingCustomer: isLoading,
    mutate,
  };
};

export { useGetCustomersByPage, useGetCustomer };
